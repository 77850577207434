<template>
  <div id="app">
    <div class="head-shadow">
      <div class="header" @click="refresh">
        <div style="width: 1200px;margin: 0 auto;"><img height="60px" src="./assets/img/logo.png" alt=""></div>
      </div>
    </div>

    <div class="app-con">
      <router-view />
    </div>

    <div class="foot">
      <div>
        <el-row :gutter="0">
          <el-col :span="24" class="foot-1">
            <!-- <div class="foot-1"> -->
            <ul class="ul-outer">
              <li><span @click="goto('/aboutUs')">About Us</span></li>
              <li><span @click="goto('/privacyStatement')">Privacy Statement</span></li>
              <li><span @click="goto('/cookieStatement')">Cookie Statement</span></li>
              <li><span @click="goto('/termsofUse')">Terms of Use</span></li>
              <li><span @click="goto('/contact')">Contact</span></li>
              <li><span @click="goto('/faq')">FAQ</span></li>
              <li><span @click="goto('/refundPolicy')">Refund Policy</span></li>
            </ul>

            <!-- </div> -->
            <div class="icon-icons">
              <div class="foot-bottom">
                <div class="foot-icon">
                  <div class="instagram" @click="gotoFaceBook('https://www.instagram.com')">
                    <img src="./assets/img/Instagram.png" alt="">
                  </div>
                  <div class="facebook" @click="gotoFaceBook('https://www.facebook.com')">
                    <img src="./assets/img/facebook.png" alt="">
                  </div>
                  <div class="tiktok" @click="gotoFaceBook('https://www.tiktok.com')">
                    <img src="./assets/img/tiktok.png" alt="">
                  </div>
                  <div class="youtube" @click="gotoFaceBook('https://www.youtube.com')">
                    <img src="./assets/img/youtube.png" alt="">
                  </div>
                  <div class="linkedin" @click="gotoFaceBook('https://www.linkedin.com')">
                    <img src="./assets/img/linkedin.png" alt="">
                  </div>
                </div>
              </div>
              <div class="icon-baoder"></div>
              <div class="foot-pay">
                <div class="foot-paycon">
                  <img src="./assets/img/zhifu_1.png" alt="">
                </div>
                <div class="foot-paycon">
                  <img src="./assets/img/zhifu_2.png" alt="">
                </div>
                <div class="foot-paycon">
                  <img src="./assets/img/zhifu_3.png" alt="">
                </div>
                <div class="foot-paycon">
                  <img src="./assets/img/zhifu_4.png" alt="">
                </div>
              </div>
            </div>
            <div class="foot-bottom">© 2024 QIANYUAN. All Rights Reserved.</div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import './style/app.css';
export default {
  name: 'app',
  data() {
    return {
      web: this.$utils.isDesktop(),
      isRefresh: true,
      dialogVisible: false,

    }
  },
  computed: {
  },
  methods: {
    ...mapMutations(['getnumGame']),
    handleOpen() { },
    handleClose() { },
    refresh() {
      this.$router.push('/')
      sessionStorage.setItem('numGame', '1')
      this.getnumGame()
    },
    goto(path) {
      this.$router.push(path)
    },
    gotoFaceBook(url) {
      window.open(url)
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
.foot {
  position: relative;
  bottom: 0;
  height: 150px;
  color: #fff;
  width: 100%;
  background: #010714;
}

.ul-outer {
  font-size: 14px;
  text-decoration: underline;
  display: flex;
  justify-content: center;

  li {
    margin-right: 40px;

    span {
      cursor: pointer;
      color: #fff;
      font-weight: 800;
    }
  }
}

.foot-bottom {
  font-size: 14px;
  font-weight: 800;
  text-align: center;
}

.home-icon {
  width: 1200px;
  margin: 0 auto;

  .home-img {
    position: fixed;
    top: 20px;
    width: 66px;
    height: 66px;
    margin-left: -34px;
    border-radius: 50%;
    background: #ecf5ff;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
      padding: 10px;
      box-sizing: border-box;
    }
  }
}

.foot-icon {
  display: flex;
  justify-content: center;

  img {
    display: block;
    margin-left: 14px;
    cursor: pointer;
  }
}

.app-con {
  min-height: calc(100vh - 150px - 70px);
  margin-top: 70px;
}

.foot-paycon {
  margin-left: 18px;
}

.foot-pay {
  display: flex;
  align-items: center;
  justify-content: center;
}


.imgGames {
  width: 150px;
  position: absolute;
  right: 76px;
  bottom: 14px;
}

.foot-1 {
  margin: 0 auto;
  padding: 18px 0;
  background: #010714;
}

.header {
  height: 70px;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  top: 0;
  background-color: #010714 !important;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 0.14px 2.29266px, rgba(0, 0, 0, 0.05) 0px 0.37px 4.42626px, rgba(0, 0, 0, 0.09) 0px 3px 7px;
}

.icon-icons {
  display: flex;
  width: 1200px;
  margin: 15px auto;
  justify-content: center;
  background: #010714;
  align-items: center;
}

.icon-baoder {
  width: 0px;
  height: 20px;
  border: 1px solid #FFF;
  margin-left: 15px;
}
</style>
